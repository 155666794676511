import User from '@/api/v2/user';
import { reactive } from 'vue';

const UserState = reactive({
        info: null,
        defaultInfo: {
                "id": 0,
                "chat_id": 0o0,
                "username": "Unknown",
                "access_level": 100,
                "game": {
                        "power": 0,
                        "energy": 0,
                        "balance": 0
                },
                "boosters": null,
                "is_bot": false,
                "is_premium": false,
                "language_code": "ru",
                "ref_code": "",
                "ref_id": null,
                "runes": [],
                "wallet": []
        },
        async login(chatId) {

                const loginResult = await User.login(chatId);
                if (loginResult.statusCode === 200 && loginResult.user) {
                        this.info = loginResult.user
                        localStorage.user = JSON.stringify(loginResult.user)
                }
        },
        async update() {
                const showResult = await User.show();
                if (showResult.statusCode === 200 && showResult.user) {
                        this.info = showResult.user
                        localStorage.user = JSON.stringify(showResult.user)
                } else {
                        if (localStorage.chatId) await UserState.login(localStorage.chatId)
                }
        },


})
export default UserState