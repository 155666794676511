/**
 * onboarding, main, wallet, friends, home
 */

export default [
    {
            path: 'wallet',
            name: 'wallet',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Wallet.vue'),
    },
    {
            path: 'wallet/deposit/:address',
            name: 'deposit',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Input form',
            },
            component: () => import('@/views/finbridge/runa/components/wallet/Deposit.vue'),
    },
    {
            path: 'transactions',
            name: 'transactions',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Transaction.vue'),
    },
    {
            path: 'payment/:hash',
            name: 'payment',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Input form',
            },
            component: () => import('@/views/finbridge/runa/components/Payment.vue'),
    },
]