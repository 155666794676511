import router from '@/router'
import axios from 'axios'

const axiosInsV2 = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.API_URL + '/api/v2',
    withCredentials: true,
})

axiosInsV2.interceptors.response.use(response => response, error => {

    if (error?.response?.status === 401) {
        localStorage.clear()
        // Redirect to login page
        if (this != null && this.$router != null) {
            router.push({ name: 'landing' })
        }
    }
    return error
})

export default axiosInsV2
