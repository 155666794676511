import axiosInsV2 from '@/libs/axiosV2';

export default class User {
	static async login(chatId='') {
		if(chatId==''){
			chatId = localStorage.getItem('chatId');
		}
		let resp =  await axiosInsV2.get(`/user/get-by-chat-id`, {
			params: {
				"chat_id": chatId
			}
		})
		User.checkTasks(resp.data.user.tasks);
		return resp.data;
	}
	static async show() {
		return await axiosInsV2.get(`/user`).then(response => response.data).catch()
	}

	static async connectWallet(address, balance) {
		return (await axiosInsV2.post(`/user/connect-wallet`, { address, balance })).data
	}

	static checkTasks(tasks){
		let hasUnclaimedTask = false;
		for (const task of tasks) {
			if(task.status == 'claim'){
				hasUnclaimedTask = true;
				break;
			}
		}
		localStorage.setItem('hasUnclaimedTask', hasUnclaimedTask);
	}
}