import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.API_URL + '/api/v1',
    // timeout: 1000,
    withCredentials: true,
    // headers: { 'Content-Type': 'multipart/form-data' },
})

axiosIns.interceptors.response.use(response => response, error => {

    if (error?.response?.status === 401) {
        localStorage.clear()
        // Redirect to login page
        if (this != null && this.$router != null) {
            router.push({ name: 'landing' })
        }
    }
    return error
})

Vue.prototype.$http = axiosIns

export default axiosIns
