import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

let lang = getCookie('lang')

if (lang === '') { // Перезаписываем только если пустая
    lang = 'en'
    document.cookie = 'lang=' + lang
}

function loadLocaleMessages() {
    const locales = require.context(
        './locales',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i,
    )
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(['ru|en'])[0]
        if (matched) {
            const locale = matched
            messages[locale] = Object.assign(messages[locale] ?? {}, locales(key))
        }
    })
    return messages
}
function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    return parts.pop().split(';').shift()
}

export default new VueI18n({
    locale: lang,
    fallbackLocale: 'en',
    globalInjection: true,
    messages: loadLocaleMessages(),
    silentTranslationWarn: true,
})
