/**
 * onboarding, main, wallet, friends, home
 */

export default [
    {
            path: 'onboarding',
            name: 'onboarding',
            meta: {
                    resource: 'CommonPages',
                    layout: 'full',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Onboarding.vue'),
    },
	{
		path: 'security',
		name: 'security',
		meta: {
				resource: 'CommonPages',
				layout: 'full',
				hide: true,
				sitemap: { ignoreRoute: true },
				pageTitle: 'Runegame',
		},
		component: () => import('@/views/finbridge/runa/Security.vue'),
	},
    {
            path: 'academy',
            name: 'academy',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Academy.vue'),
    },
    {
            path: 'invites',
            name: 'invites',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Invites.vue'),
    },
    {
            path: 'tasks',
            name: 'tasks',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Tasks.vue'),
    },
    {
            path: 'leaderboard',
            name: 'leaderboard',
            meta: {
                    resource: 'CommonPages',
                    layout: 'game',
                    hide: true,
                    sitemap: { ignoreRoute: true },
                    pageTitle: 'Runegame',
            },
            component: () => import('@/views/finbridge/runa/Leaderboard.vue'),
    },
]