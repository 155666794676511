import Vue from 'vue';
import VueRouter from 'vue-router';
// Routes
import i18n from "@/libs/i18n";
// import common from './finbridge/common'
// import lk from './finbridge/index'
import runa from './finbridge/runa/index';

const apps = () => import('./apps/index');
const dashboard = () => import('./dashboards/dashboard');
const formsTable = () => import('./forms-tables/forms-tables');
const pages = () => import('./pages/pages');
const uiElements = () => import('./ui-elements/index');
const chartsMaps = () => import('./charts-maps/charts-maps');

const children = true//process.env.DEV_MENU == 'true' 
    ? [
        ...(await apps()).default,
        ...(await dashboard()).default,
        ...(await pages()).default,
        ...(await formsTable()).default,
        ...(await uiElements()).default,
        ...(await chartsMaps()).default,
        ...runa
        // ...lk,
        // ...common,
    ] : [
        // ...lk,
        // ...common,
        ...runa
    ];

const routes = [
            {
                path: "",
                redirect: "/" + i18n.locale,
            },
            {
                path: "/:locale(ru|en)?/",
                component: {
                    render: h => h('router-view'),
                },

                children: children
            },
            {
                path: "*",
                redirect: i18n.locale,
            },

        ]
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    const result = originalPush.call(this, location, onComplete, onAbort)
    if (result) {

        if (result) {
            return result.catch(err => {
                console.warn(`consumed router exception: ${err}`)
            })
        }
    }
    return result
}

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, _, next) => {

    // if (!localStorage.user) {
    //     await UserState.login(to.query.chat_id);
    //     localStorage.chatId = to.query.chat_id;
    //     return next({ name: 'home' })
    // }
    const lang = to.fullPath.match(['ru|en'])?.[0]

    if (lang && lang !== i18n.locale) {
        i18n.locale = lang
        document.cookie = 'lang=' + lang
    }
    return next()

    // if (canNavigate(to)) {
    //     return next()
    // } else {
    //     return next({ name: getHomeRoute(userData ? userData.role : null) })
    // }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
