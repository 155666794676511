import Vue from 'vue'
// import * as Sentry from "@sentry/vue"
// import { BrowserTracing } from "@sentry/tracing"
import i18n from '@/libs/i18n'
import PortalVue from 'portal-vue'

import App from './App.vue'
import router from './router'
import store from './store'

// import VueIpfs from './libs/vue-ipfs'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
// import '@/libs/toastification'
import '@axios'
// Connect fonts
import '@core/assets/fonts/ibm_plex_mono/iconfont.css'
import '@core/assets/fonts/montserrat/iconfont.css'
import '@core/assets/fonts/rubik_wet_paint/iconfont.css'

import '@core/scss/core.scss'
// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(PortalVue)
// Vue.use(VueReCaptcha, {
//     siteKey: process.env.RECAPCHA_SITE_KEY,
//     loaderOptions: {
//         autoHideBadge: true,
//     },
// })
// Vue.use(VueCarousel)
// if (process.env.NODE_ENV === 'production') {
//     Sentry.init({
//         Vue,
//         environment: process.env.NODE_ENV,
//         release: `${process.env.NODE_ENV}`,
//         dsn: process.env.SENTRY_DSN,
//         integrations: [
//             new BrowserTracing({
//                 tracingOrigins: [process.env.SENTRY_HOST, /^\//],
//             }),
//         ],
//         tracesSampleRate: 1,
//         tracingOptions: {
//             trackComponents: true,
//         },
//         // Vue specific
//         attachProps: true,
//         attachStacktrace: true,
//     })
// }


Vue.config.productionTip = false

new Vue({
    router,
    // watch: {
    //     '$route'(to, from) {
    //         console.log(to.query.chat_id)
    //     }
    // },
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
